(function ($, skrollr, window, document) {
  'use strict';

  function animateUnderline() {
    var $underline = $('.logo .underline');

    return $.Velocity.animate($underline, {
      width: '98%'
    }, {
      duration: 500,
      easing: 'easeOutQuad'
    });
  }

  function animateText() {
    var $text = $('.logo h1');

    $.Velocity.hook($text, 'translateY', '100%');
    return $.Velocity.animate($text, {
      translateY: '0%'
    }, {
      duration: 300,
      easing: 'easeOutCubic'
    });
  }

  function fillCircles() {
    var $circles = $('li.circle:not(.empty)');

    $.each($circles, function() {
      var $circle = $(this);
      var $visual = $('<div>', {
        class: 'circle-visual'
      });

      $circle.html($visual);
    });
  }

  function sortCircles(element) {
    var $circles = $(element + ' li.circle:not(.empty)');
    var circles = {};

    $.each($circles, function() {
      var $circle = $(this);
      var $visual = $circle.children();
      var level = $circle.attr('level');

      if(typeof circles[level] !== 'undefined') {
        circles[level].push($visual);
      } else {
        circles[level] = [ $visual ];
      }
    }); 

    return circles;
  }

  function animateCircle($visual, delay) {
    setTimeout(function() {
      $.Velocity.hook($visual, 'scale', 0);
      $visual.velocity({
        scale: 1
      }, {
        duration: 200,
        easing: 'easeOutCubic'
      });
    }, delay);
  }

  function animateCircles(list) {
    var z = 0;

    for (var key in list) {
      if(!list.hasOwnProperty(key)) {
        continue;
      }

      var circles = list[key];

      for(var c = 0; c < circles.length; c++){
        var $visual = circles[c];

        animateCircle($visual, z);
        z = z + 30;
      }
    }
  }

  function animateAllCircles() {
    fillCircles();

    var left = sortCircles('.splash .circles-left');
    var right = sortCircles('.splash .circles-right');

    animateCircles(right);

    setTimeout(function() {
      animateCircles(left);
    }, 400);
  }

  function animateMenu() {
    var $menu = $('nav');

    return $.Velocity.animate($menu, {
      top: '-3em'
    }, {
      duration: 300,
      easing: 'easeOutQuad'
    });
  }

  function animateSplash() {
    animateAllCircles();

    setTimeout(function() {

      animateUnderline();
      setTimeout(animateText, 400);
      setTimeout(animateMenu, 800);
    }, 250);
  }
  
  function hideDrawer() {
    var $slider = $('#drawer .drawer-content');
    var $circles = $('#drawer .circle-visual');

    $slider.velocity('stop').velocity({ 'translateY': '-101%'}, { duration: 400, easing: 'easeOutQuad' });
    $circles.velocity('stop').velocity({ 'scale': '0'}, { duration: 200 });
    $('.portrait').removeClass('open-drawer');

  }
  
  function showDrawer() {
    var $slider = $('#drawer .drawer-content');
    var drawerCircles = sortCircles('#drawer');

    $.Velocity.hook($slider, 'translateY', '-101%');
    $slider.velocity('stop').velocity({ 'translateY': '0%'}, { duration: 400, easing: 'easeOutQuad' });
    $('.portrait').addClass('open-drawer');

    setTimeout(function() {
      animateCircles(drawerCircles);
    }, 300);
  }

  $(function () {
    $(document).ready(function() {
      new vUnit({
        CSSMap: {
          'section.splash': {
            property: 'height',
            reference: 'vh'
          },
          '.mask .background': {
            property: 'height',
            reference: 'vh'
          }
        },
        onResize: function() {
          // disable skrollr
          if ($(window).width() <= 640) {
            skrollr.init().destroy();
          }
        }
      }).init();

      setTimeout(animateSplash, 100);

      if ($(window).width() > 640) {
        skrollr.init();
      }

      var visible = false;

      $('#more').click(function() {
        if(visible) {
          hideDrawer();
          visible = false;
        } else {
          showDrawer();
          visible = true;
        }
      });

      $('nav a').click(function(e) {
        var id = $(this).attr('id');
        var $section = $('section.' + id);

        e.preventDefault();
        $('body').velocity('scroll', { duration: 1000, offset: $section.offset().top  - 100 });
      });
    });
  });

})(jQuery, skrollr, window, document);
